import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Text } from "components/Typography";
import { Link } from "components/Utils";
import List from "components/List";
import { Avatar } from "components/User";

import { Grid, Cell, Border, Spacing } from "components/Layout";

import { RelatedBox } from "./Related";

import { useNav } from "../queries";
import useMarketIndices from "hooks/useMarketIndices";

const NavLabel = styled(Text)`
   color:#fff;
   font-size:0.95rem;
${Link} { color: #fff; }
`

const SubMenu = styled.div`
  display: block;
  background-color: ${props => props.transparent ? 'transparent' :'#0f293d' };
`;

const SubBox = styled.div`
  padding: 1rem 0;
`;

const MainNavList = styled(List)`
@media only screen and (max-width:1024px){
display: none;
}
  position: relative;
  width:100%;
  > li {
    padding:0.25rem 0.5rem 1rem;
    background-color: transparent;
    transition: background-color 200ms;
    &:hover {
      background-color: ${props => props.theme.color.accent };
      > div {
        height: auto;
      }
      ${NavLabel} {
color: ${props => props.theme.color.dark };
      }
    }
&:first-child li + li {
font-size:0.85rem;
a { padding-left: 1rem;
}
  }
  a {
    display: inline-block;
  }
  
`;


const NavigationList = styled(Grid)`
  position: absolute;
  left:0;
  top:36px;
  right:0;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,0.75);
  height:0;
  overflow:hidden;

${List} {
  height:100%;
  border-right: 1px solid #eee;
  padding: 1rem 0;
}
li {
  
  width:100%;
  a {
    display:block;
    width:100%;
    height:100%;
    padding: 0.5rem;
  }
}

li:hover {
  a {
    background: #dadada;
  }
  > ${SubBox} {
    visibility: visible;
  }
}
}`;

const UserLi = styled.li`
  position: absolute;
  right: 0;
  top: -8px;
  div {
  vertical-align: middle;
  }
`;

const GCILi = styled.li`
  padding:0 !important;
  line-height: 30px;
  img {
  float:left;
  vertical-align: middle;
  margin-right: 0.5em;
  opacity:0.25;
  }
  ${Link} {
  color: #fff;
  padding-right: 0.5em;
  }
  &:hover {
  background-color: #fff !important;
  
  ${Link} {
  color: ${ props => props.theme.color.gci };
  
  }
  img {
  opacity:1;
  }
  }
`;

const MobileNavList = styled(List)`
li { padding: 0.5rem }
li:nth-child(2)  li + li { display: none }
`

const Related = ({ relatedKey, links, ...props }) => {
  return <SubBox>
    <RelatedBox relatedKey={relatedKey} links={links} />
  </SubBox>
}

const NavigationChildren = ({ links, relatedKey, ...props }) => {
  if(links.length === 1){
    return <NavigationList>
      <Cell col={12}>
        <Related relatedKey={relatedKey} links={links} />
      </Cell>
    </NavigationList>
  }
  return <NavigationList>
  <Cell lgCol={3} mdCol={4}>
  <List>
  { links.map( link => {
    return <li key={link.path}>
      <Link href={link.path}>
        {link.label}
      </Link>
      
    </li>
  })}
  </List>
  </Cell>
  <Cell lgCol={9} mdCol={8}>
    <Related relatedKey={relatedKey} links={ links } />
  </Cell>
  </NavigationList>
}


function UserNavigation({ vertical, verticalSmall, onLogOut }) {
  const login = useSelector((state) => state.userData.user_login);
  const { name, avatar } = useSelector((state) => state.userData);
  if(!login){
    return null;
  }
  return (<>
    <NavLabel>{login} <Avatar src={avatar} size="32px" /></NavLabel>
    <NavigationList>
      {/*<li>
          <Link href={`/members/${login}/profile/`}>Profile</Link>
          </li>
          <li>
          <Link href="/edit-profile/">Edit Profile</Link>
          </li>
          <li>
          <Link href="/settings/">Settings</Link>
          </li>*/}
      <li>
        <Link href="/watchlist-extended/">Watchlist</Link>
      </li>
      <li>
        <Link href={`/me/`}>Timeline</Link>
      </li>
      <li>
        <Link onClick={onLogOut}>Log out</Link>
      </li>
    </NavigationList>
  </>
  );
}

function MobileUserNavigation({ onLogOut, children }) {
  const login = useSelector((state) => state.userData.user_login);
  const { name, avatar } = useSelector((state) => state.userData);
  if(!login){
    return <Spacing all={1}>
      {children}
    </Spacing>;
  }
  return (<>
    <NavLabel><Text white bold>{login} <Avatar src={avatar} size="32px" /></Text></NavLabel>
    <List>
      <li>
        <Link onClick={onLogOut}><Text white>Log out</Text></Link>
      </li>
    </List>
  </>
  );
}

const GCILink = ({ ...props }) => {
  return <GCILi>
    
    <Link href="/gci/"><img src="/gci-icon.png" width="34" height="44" /> GCI</Link>
  </GCILi>
}

function MainNavigation({ vertical, horizontal }) {


  const { data: structure } = useNav();

  const marketIndices = useMarketIndices();
  
  const ParentMarkets = [ "MAIN:ALL", "AIM:ALL", "AQSE:ALL" ].map( market => marketIndices.find( idx => idx.indexTicker === market )).filter( i => !!i );

  

  const mainMarketIndices = ['ASX','UKX','MCX','NMX'].map( market => marketIndices.find( idx => idx.indexTicker === market )).filter( i => !!i );
  const aimMarketIndices = ['AXX', 'A100','A50'].map( market => marketIndices.find( idx => idx.indexTicker === market )).filter( i => !!i );
  const aqseMarketIndices =  ['AQSE:AXS','AQSE:APX'].map( market => marketIndices.find( idx => idx.indexTicker === market )).filter( i => !!i );



  const markets = ParentMarkets.length > 0 ? [
    ParentMarkets[0],
    ...mainMarketIndices,
    ParentMarkets[1],
    ...aimMarketIndices,
    ParentMarkets[2],
    ...aqseMarketIndices
  ].map( idx => ({ label: idx.indexName, path: `/index/${idx.indexLeafname}/`, active: true }) ) : []


  if(structure){
    structure[1].links = markets;
  }

  
  return (
    <MainNavList horizontal vertical>
      <>
        { structure && structure.map( root => {
            return <li key={root.relatedKey || root.label}>
              <NavLabel as="h2">
                {root.path && <Link href={root.path}>{root.label}</Link> }
                {!root.path && root.label}
              </NavLabel>
              <NavigationChildren links={root.links} relatedKey={root.relatedKey} />
            </li>
          })}

        <GCILink />

        <UserLi>
          <UserNavigation />
        </UserLi>
      </>
    </MainNavList>
  );
}

const Navigation = ({ isWatchlist, ...props}) => {
  return <SubMenu>
      
      <Border>
        <Grid middle>
          <MainNavigation horizontal />
        </Grid>
      </Border>
      
  </SubMenu>
}

const MobileNavigation = ({ ...props }) => {
  const { data: structure } = useNav();

  return <MobileNavList>
  <li>
  <MobileUserNavigation children={props.children} />
  </li>
  
  { structure && structure.map( root => {
    return <li key={root.relatedKey || root.label}>
    <NavLabel as="h2" bold>
    {root.path && <Link href={root.path} onClick={props.toggle}>{root.label}</Link> }
    {!root.path && root.label}
    </NavLabel>
    <List>
    { root.links.map( link => {
      return <li key={link.path}>
        <Link href={link.path} onClick={props.toggle}>
          <Text white>{link.label}</Text>
        </Link>
      </li>
    })}
  </List>
  </li>
  })}
  </MobileNavList>
}


export { Navigation, MobileNavigation }

import { useQuery, useQueryClient } from "react-query";

import rnsFilters from "components/RNS/filterDefinitions";



const nav = [
  {
    label: "RNS",
    active: true,
    relatedKey: "rns",
    path: "/rns/",
    links : [
      {
        label: "Live RNS",
        path: "/rns/",
        active: true
      },
      ...rnsFilters.filter( f => !!f.slug ).map( f => ({
        label: f.label,
        path: `/rns/${f.slug}/`,
        active: true
      }))
    ]
  },
  {
    label: "Stock Markets",
    active: true,
    path: "/uk-stock-markets-sectors/",
    relatedKey: "market-sectors",
    links : []
  },
  {
    label: "News & Views",
    active: true,
    relatedKey: "news-views",
    path: "/all-articles/vox-newswire/",
    links: [
      {
        label: "Vox Newswire",
        path: "/all-articles/vox-newswire/",
        category: "Vox Newswire",
        active: true,
      },
      {
        label: "View From Vox",
        path: "/all-articles/view-from-vox/",
        category: "View From Vox",
        active: true,
      },
      {
        label: "Market Reports",
        path: "/all-articles/market-reports/",
        category: "Market Reports",
        active: true,
      },
      {
        label: "Broker Recommendations",
        path: "/all-articles/broker-recommendations/",
        category: "Broker Recommendations",
        active: true,
      },
      {
        label: "Company News",
        path: "/all-articles/company-news/",
        category: "Company News",
        active: true,
      },
      {
        label: "Risers & Fallers",
        path: "/all-articles/risers-fallers/",
        category: "Risers & Fallers",
        active: true,
      },
      {
        label: "Macro Views",
        path: "/all-articles/macro-views/",
        category: "Macro Views"
      },
      {
        label: "Short Views",
        path: "/series/short-views/",
        active: true,
      },
      {
        label: "Taking Stock",
        path: "/all-articles/taking-stock/",
        category: "Taking Stock",
        active: true,
      },
      {
        label: "Hot Stocks",
        path: "/all-articles/hot-stocks/",
        category: "Hot Stocks",
        active: true,
      },
      {
        label: "Analyst Comment",
        path: "/all-articles/analyst-comment/",
        category: "Analyst Comment",
        active: true,
      },
      {
        label: "Crypto",
        path: "/all-articles/crypto/",
        category: "Crypto",
        active: false,
      },
      {
        label: "IPO Watch",
        path: "/all-articles/ipo-watch/",
        category: "IPO Watch",
        active: false,
      },
      {
        label: "Partner Content",
        path: "/all-articles/partner-content/",
        category: "Partner Content",
        active: false,
      },
      {
        label: "Company Diary",
        path: "/all-articles/company-diary/",
        category: "Company Diary",
        active: false,
      }
    ]
  },
  {
    label: "Videos & Podcasts",
    active: true,
    relatedKey: "videos-podcasts",
    path: "/all-articles/vox-markets-podcast/",
    links: [
      {
        label: "Vox Markets Podcast",
        path: "/all-articles/vox-markets-podcast/",
        category: "Vox Markets Podcast",
        active: true,
      },
      {
        label: "Company Q&A",
        path: "/all-articles/q-and-a/",
        category: "Q&A",
        active: true,
      },
      {
        label: "Fund Manager Q&A",
        path: "/all-articles/fund-manager-q-and-a/",
        category: "Fund Manager Q&A",
        active: true,
      },
      {
        label: "Taking Stock",
        path: "/all-articles/taking-stock/",
        category: "Taking Stock",
        active: true,
      },
      {
        label: "Macro Views",
        path: "/all-articles/macro-views/",
        category: "Macro Views",
        active: false,
      }
    ]
  },
  {
    label: "Market Data",
    active: false,
    relatedKey: "market-data",
    links: [
      {
        label: "",
        path: ""
      }
    ]
  },
  {
    label: "Research",
    active: true,
    relatedKey: "broker-research",
    path: "/all-articles/company-broker-research/",
    links: [
      {
        label: "Company Research",
        path: "/all-articles/company-broker-research/",
        category: "Company Broker Research",
        active: true,
      },
      {
        label: "Investment Trust Research",
        path: "/all-articles/investment-trust-research/",
        category: "Investment Trust Research",
        active: false,
      }
    ]
  },
  {
    label: "Sectors & Themes",
    active: true,
    relatedKey: "sectors-themes",
    path: "/all-articles/sector-special/",
    links: [
      {
        label: "Vox Sector Special",
        path: "/all-articles/sector-special/",
        category: "Vox Sector Special",
        active: true,
      },
      {
        label: "Vox Sector Special Video",
        path: "/all-articles/sector-special-media/",
        category: "Vox Sector Special Media",
        active: false,
      }
    ]
  },
  {
    label: "Companies",
    active: true,
    relatedKey: "companies",
    path: "/all-articles/q-and-a/",
    links: [
      {
        label: "Company Spotlight",
        path: "/all-articles/company-spotlight/",
        category: "Company Spotlight",
        active: true,
      },
      {
        label: "Company Q&A videos/podcasts",
        path: "/all-articles/q-and-a/",
        category: "Q&A",
        active: true,
      }
    ]
  },

  {
    label: "Funds & Trusts",
    active: true,
    relatedKey: "funds-trusts",
    path: "/all-articles/fund-manager-q-and-a/",
    links: [
      {
        label: "Fund Focus",
        path: "/all-articles/fund-focus/",
        category: "Fund Focus",
        active: false,
      },
      {
        label: "Fund Sector Report",
        path: "/all-articles/fund-sector-report/",
        category: "Fund Sector Report",
        active: false,
      },
      {
        label: "Fund Manager Q&A",
        path: "/all-articles/fund-manager-q-and-a/",
        category: "Fund Manager Q&A",
        active: true,
      }
    ]
  },
  {
    label: "Growth Company Investor",
    active: false,
    relatedKey: "growth-company",
    links: [
      {
        label: "Idea of the Week",
        path: "/all-articles/idea-of-the-week/"
      },
      {
        label: "GCI Portfolio",
        path: "/all-articles/gci-portfolio/",
      },
      {
        label: "Stock screens",
        path: "/all-articles/stock-screens/",
      }
    ]
  },
  {
    label: "Events",
    relatedKey: "events",
    active: false,
    links: [
      {
        label: "Vox Sectors live",
        path: "/all-articles/vox-sectors-live/"
      },
      {
        label: "Vox Funds live",
        path: "/all-articles/vox-funds-live/",
        category: ""
      }
    ]
  },
  {
    label: "",
    active: false,
    links: [
      {
        label: "",
        path: ""
      }
    ]
}
]

async function fetchNav() {
  const data = await Promise.resolve(nav);
  return data.filter( root => !!root.active ).map( root => {
    const subCategories = root.links.filter( link => !!link.active );
    return { ...root, links: subCategories };
  });
  //return await fetch(`https://api.voxmarkets.co.uk/v4/cms/navigation`).then(res => res.json());
}

function useNav(){
  return useQuery(
    "nav",
    () => fetchNav(),
    {
      staleTime: 300000, // 5min
    }
  );
}

export {
  fetchNav,
  useNav
}
